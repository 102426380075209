import InfiniteLoading from 'vue-infinite-loading'
import Vue from 'vue/dist/vue.esm'
import TurbolinksAdapter from 'vue-turbolinks'
import _ from 'lodash'

import AxiosRequest from "../utils/axios-request"

# FILTERS
import toCurrency from '../filters/to_currency'

# Local imports
import AddCart from '../methods/products/add_cart'
import Delivery from '../methods/products/delivery'
Vue.use TurbolinksAdapter

document.addEventListener 'turbolinks:load', ->
  el = document.getElementById 'show_product'
  return false unless el?
  new Vue {
    el
    filters: {toCurrency}
    data: -> {
      product:[]
      favourites: []
      favourite: false
      options: []
      default_option: []
      selected_address: undefined
      selected_option: undefined
      selected_shipping: undefined
      selected_pickup: undefined
      tmp_option_selected: undefined
      store_select: undefined
      return_cause: undefined
      price_shipping: 0
      quantity: 1
      min: 1
      max: 0
    }
    mounted: ->
      id = document.getElementById('id').dataset.id
      title = document.getElementById('title').dataset.title
      query = $.get "/productos/#{title}-id_#{id}.json"
      query.done (res) =>
        @max = res.product.units
        @product = res.product
        @options = res.options
        @default_option = res.default_option
        @favourites = res.favourites
        @productFavourite(res.product.id, res.favourites)
    methods: {
      AddCart
      Delivery
      DeleteFavourite: (product, uid) ->
        Swal.fire(
          title: '¿Estás seguro?'
          text: ''
          icon: 'warning'
          showCancelButton: true
          cancelButtonText: 'Cancelar'
          confirmButtonText: 'Sí, quitar favorito').then (result) =>
            if result.value
              @favourite = false
              AxiosRequest("/customers/favourites/remove", {item_id: product, customer_id: uid}, 'post').then ({data}) =>
                Swal.fire({
                  title: data.message
                  icon: 'success'
                  toast: true
                  position: 'top-end'
                  timer: 2000
                })
            else if result.dismiss == Swal.DismissReason.cancel
              Swal.fire({
                title: 'Cancelado'
                icon: 'error'
                toast: true
                position: 'top-end'
                timer: 2000
              }).then ->
                console.log 'Cancelado'
            return
      Favourite: (product, uid) ->
        AxiosRequest('/customers/favourites', {item_id: product, customer_id: uid}, 'post').then ({data}) =>
          @favourite = true
          Swal.fire({
            title: data.message
            icon: 'success'
            toast: true
            position: 'top-end'
            timer: 2000
          }).then ->
            console.info 'success'
      canAdd: (options = null, default_option) ->
        if options
          if @selected_pickup
            flag = true if @selected_option == options.product_inventory_id
          else if @selected_shipping
            flag = true if @selected_option == options.product_inventory_id
        else if default_option == true
          if @selected_pickup
            flag = true
          else if @selected_shipping
            flag = true
        else
          flag = false
        not (flag and @quantity >= 1)
      productFavourite: (product, favourites) ->
        @favourite = favourites?.some((e) => (e.item_id == product))
    }
    watch: {
      tmp_option_selected: (val) ->
        @max = @options[val].unit
        @selected_shipping = false
        @selected_pickup = false
        @store_select = undefined
      max: (val) ->
        if @tmp_option_selected != undefined
          if @quantity >= @max
            @quantity = val
      quantity: (val) ->
        if @quantity >= @max
          @quantity = @max
        if val == 0 || val == null
          @quantity = @min
    }
    computed: {
      any_delivery: ->
        flag = false
        if @selected_shipping == true || @selected_pickup == true
          flag = true
        flag
    }
  }
